import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Meta from 'vue-meta';

Vue.use(Vuex);
Vue.use(Meta);

export default new Vuex.Store({
  state: {
    currentRoute: null,
    siteInfo: {},
    isMobile: false,
  },
  getters: {
    currentComponent: (state) => {
      const routeName = state.currentRoute?.name;
      switch(routeName) {
        case 'index':
          return () => import('@/views/index.vue');
        case 'main':
          return () => import('@/views/pageMain.vue');
        case 'about':
          return () => import('@/views/about/pageAbout.vue');
        case 'greetings':
          return () => import('@/views/about/pageGreetings.vue');
        case 'directors':
          return () => import('@/views/about/pageDirectors.vue');
        case 'location':
          return () => import('@/views/about/pageLocation.vue');
        case 'award':
          return () => import('@/views/award/pageAward.vue');
        case 'recommendation':
          return () => import('@/views/award/pageRecommend.vue');
        case 'recipient':
          return () => import('@/views/award/pageRecipient.vue');
        case 'ceremony':
          return () => import('@/views/award/pageCeremony.vue');
        case 'hongjinki':
          return () => import('@/views/yumin/pageHongjinki.vue');
        case 'Onjium':
            return () => import('@/views/onjium/aboutOnjium.vue');
        case 'cloth':
          return () => import('@/views/onjium/onjiumCloth.vue');
        case 'taste':
          return () => import('@/views/onjium/onjiumTaste.vue');
        case 'house':
          return () => import('@/views/onjium/onjiumHouse.vue');
        case 'collected':
          return () => import('@/views/collected/pageCollected.vue');
        case 'collectedList':
          return () => import('@/views/collected/pageCollectedList.vue');
        case 'collectedDetail':
          return () => import('@/views/collected/pageCollectedDetail.vue');
        case 'program':
          return () => import('@/views/etc/pageProgram.vue');
        case 'scholarship':
          return () => import('@/views/etc/pageScholarship.vue');
        case 'religion':
          return () => import('@/views/etc/pageReligion.vue');
        case 'global':
          return () => import('@/views/archive/pageGlobal.vue');
        case 'heritageTomorrow':
          return () => import('@/views/archive/heritageTomorrow.vue');
        case 'PineTree':
          return () => import('@/views/archive/pagePineTree.vue');
        case 'academic':
          return () => import('@/views/archive/pageAcademic.vue');
        case 'notice':
          return () => import('@/views/board/pageNotice.vue');
        case 'annual':
          return () => import('@/views/board/pageDisclosure.vue');
        default:
          return null;
      }
    },
    getSiteInfo: (state) => state.siteInfo,
  },
  mutations: {
    setCurrentRoute(state, route) {
      state.currentRoute = route;
    },
    setSiteInfo(state, value) {
      state.siteInfo = value;
    },
    setMobile(state, payload) {
      state.isMobile = payload;
    },
  },
  actions: {
    updateRoute({ commit }, route) {
      commit('setCurrentRoute', route);
    },
    fetchData({ commit }) {
      commit('setMobile', /Mobi|Android/i.test(navigator.userAgent));
      return axios.get('/api/v1/introduction/foundation')
        .then(res => {
          commit('setSiteInfo', res.data.body);
        })
        .catch(err => console.error('Error fetching data:', err));
    },
  }
});