import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/scss/all.scss";

// AOS import
import AOSAnimation from 'aos';
import 'aos/dist/aos.css'; // AOS 스타일 시트 import

Vue.config.productionTip = false;

// AOS 초기화
AOSAnimation.init({
  duration: 800, // 애니메이션 지속 시간
  easing: 'ease-in-out', // 이징 함수
  once: false, // 애니메이션이 한 번만 실행되도록 설정
  anchorPlacement: 'top-bottom', // 애니메이션 시작 기준 위치
  startEvent: 'load', 
});

router.beforeEach((to, _from, next) => {
  store.dispatch('updateRoute', to);
  next();
});

new Vue({
  router,
  store,
  created() {
    this.$store.dispatch('fetchData');
  },
  render: h => h(App),
}).$mount('#app');