import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "main",
    component: () => import("@/views/index.vue"), // 인덱스 페이지
  },
  {
    path: "/",
    component: () => import("@/views/LayoutPage.vue"),
    children: [
      {
        path: "home",
        name: "main",
        component: () => import("@/views/pageMain.vue"),
      },      
      {
        path: "about/about",
        name: "about",
        component: () => import("@/views/about/pageAbout.vue"),
      },
      {
        path: "about/greetings",
        name: "greetings",
        component: () => import("@/views/about/pageGreetings.vue"),
      },
      {
        path: "about/directors",
        name: "directors",
        component: () => import("@/views/about/pageDirectors.vue"),
      },
      {
        path: "about/location",
        name: "location",
        component: () => import("@/views/about/pageLocation.vue"),
      },
      {
        path: "award/award",
        name: "award",
        component: () => import("@/views/award/pageAward.vue"),
      },
      {
        path: "award/recommendation",
        name: "recommendation",
        component: () => import("@/views/award/pageRecommend.vue"),
      },
      {
        path: "award/dialogBox",
        name: "dialogBox",
        component: () => import("@/components/dialogBox.vue"),
      },
      {
        path: "award/recipient",
        name: "recipient",
        component: () => import("@/views/award/pageRecipient.vue"),
      },
      {
        path: "award/ceremony",
        name: "ceremony",
        component: () => import("@/views/award/pageCeremony.vue"),
      },
      {
        path: "yumin/hongjinki",
        name: "hongjinki",
        component: () => import("@/views/yumin/pageHongjinki.vue"),
      },
      {
        path: "onjium/about",
        name: "Onjium",
        component: () => import("@/views/onjium/aboutOnjium.vue"),
      },
      {
        path: "onjium/cloth",
        name: "cloth",
        component: () => import("@/views/onjium/onjiumCloth.vue"),
      },
      {
        path: "onjium/taste",
        name: "taste",
        component: () => import("@/views/onjium/onjiumTaste.vue"),
      },
      {
        path: "onjium/house",
        name: "house",
        component: () => import("@/views/onjium/onjiumHouse.vue"),
      },
      {
        path: "collected/collected",
        name: "collected",
        component: () => import("@/views/collected/pageCollected.vue"),
      },
      {
        path: "collected/collectedList",
        name: "collectedList",
        component: () => import("@/views/collected/pageCollectedList.vue"),
      },
      {
        path: "collected/collectedDetail",
        name: "collectedDetail",
        props: true,
        component: () => import("@/views/collected/pageCollectedDetail.vue"),
      },
      {
        path: "etc/program",
        name: "program",
        component: () => import("@/views/etc/pageProgram.vue"),
      },
      {
        path: "etc/religion",
        name: "religion",
        component: () => import("@/views/etc/pageReligion.vue"),
      },
      {
        path: "etc/scholarship",
        name: "scholarship",
        component: () => import("@/views/etc/pageScholarship.vue"),
      },
      {
        path: "archive/global",
        name: "global",
        component: () => import("@/views/archive/pageGlobal.vue"),
      },
      {
        path: "archive/heritageTomorrow",
        name: "heritageTomorrow",
        component: () => import("@/views/archive/heritageTomorrow.vue"),
      },
      {
        path: "archive/PineTree",
        name: "PineTree",
        component: () => import("@/views/archive/pagePineTree.vue"),
      },
      // {
      //   path: "archive/academic",
      //   name: "academic",
      //   component: () => import("@/views/archive/pageAcademic.vue"),
      // },
      {
        path: "board/notice",
        name: "notice",
        component: () => import("@/views/board/pageNotice.vue"),
      },
      {
        path: "board/annual",
        name: "annual",
        component: () => import("@/views/board/pageDisclosure.vue"),
      },
      {
        path: "board/:newsType/posts/:pno",
        name: "boardDetail",
        component: () => import("@/views/board/pageBoardView.vue"),
        props: true,
      },
      {
        path: "policy/privacy",
        name: "privacy",
        component: () => import("@/views/policy/pagePrivacy.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // 항상 스크롤 위치를 최상단으로 설정
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // 모든 페이지 이동 시 스크롤을 최상단으로 설정
  window.scrollTo(0, 0);
  next();
});

export default router;
